import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import HttpStatus from "./../helpers/status.enum";

import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { checkInActions } from "./../store/checkin.slice";
import 'react-toastify/dist/ReactToastify.css';
import Termsofserevice from "./termsofserevice";
import Privacypolicy from "./privacypolicy";
import Moment from "moment";
import SpinnerLoader from "../components/SpinnerLoader";
import { commonActions } from "./../store/common.slice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Skeleton
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

function Checkedin({ checkedInPageHandle, checkingPageHandle, reservationPageHandle }) {
    // const [phone, setPhone] = useState('');
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [otpInputs, setOtpInputs] = useState(["", "", "", "", "", ""]);
    const [isFormValidOTP, setIsFormValidOTP] = useState(false);
    const [records, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [cmsContent, setCmsContent] = useState(null);


    const [authCode, setAuthCode] = useState(null);
    const [userInputOtp, setUserInputOtp] = useState(null);

    const navigate = useNavigate(); // Initialize useNavigate hook

    const dispatch = useDispatch();

    const bookingSlug = useSelector(data => {
        return data.checkIn.value;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await dispatch(checkInActions.getBookingByCode(bookingSlug));
                setData(result?.payload?.result);
                if (result?.payload?.result?.isCheckinCompleted === true) {
                    reservationPageHandle(true);
                    checkingPageHandle(false);
                    checkedInPageHandle(false);
                    // navigate("/reservationinfo");
                } else if (result?.payload?.result?.is_otp_verified === 1) {
                    if (result?.payload?.result?.is_guest_form_bypass === 1) {
                        reservationPageHandle(true);
                        checkingPageHandle(false);
                        checkedInPageHandle(false);
                        // navigate("/reservationinfo");
                    } else {
                        checkingPageHandle(true);
                        checkedInPageHandle(false);
                        // navigate("/checkingin");
                    }
                } else {
                    checkedInPageHandle(true);
                    // navigate("/checkedin");
                }
                const cmsResult = await dispatch(commonActions.getCmsContents());
                setCmsContent(cmsResult?.payload?.result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        // Call the fetchData function inside useEffect
        fetchData();
    }, []);

    // Scroll to top when component mounts or navigates
    useEffect(() => {
        // Find the position of the "Checking In" section relative to the top of the document
        const checkingInSection = document.getElementById('checkedInpage');
        const sectionPosition = checkingInSection.getBoundingClientRect().top;

        // Scroll the page to the position of the "Checking In" section
        window.scrollTo({
            // top: sectionPosition,
            behavior: 'smooth'
        });
    }, []);



    const handlePhoneChange = (value, country) => {
        setPhone(value);
        setPhoneError("");
        validateForm(value, email);
    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setEmailError("");
        validateForm(phone, value);
    };

    const validateForm = (phoneValue, emailValue) => {
        const phoneValid = phoneValue.trim() !== "";
        const emailValid =
            emailValue.trim() !== "" && /\S+@\S+\.\S+/.test(emailValue);
        if (phoneValid && emailValid) {
            setIsFormValid(true);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setIsFormValidOTP(false)
        const formData = {
            verification_code: userInputOtp,
            is_resend: 0,
            booking_slug: bookingSlug,
            auth_code: authCode
        };
        try {
            const result = await dispatch(checkInActions.verifyOtp(formData));
            if (result?.payload?.status === HttpStatus.HTTP_OK) {
                setIsLoading(false);
                setIsFormValidOTP(true)
                if (result?.payload?.result?.is_guest_form_bypass === 1) {
                    reservationPageHandle(true);
                    checkingPageHandle(false);
                    checkedInPageHandle(false);
                    // navigate("/reservationinfo");
                } else {
                    checkingPageHandle(true);
                    checkedInPageHandle(false);
                    // navigate("/checkingin");
                }
            } else {
                toast(result?.payload?.message);
                setIsLoading(false);
                setIsFormValidOTP(true)
            }
        } catch (error) {
            setIsLoading(false);
            setIsFormValidOTP(true)
            console.error('Error occurred while submitting form:', error);
        }
    };

    // Function to scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    // Scroll to top when component mounts or navigates
    const inputRefs = useRef([]); // Ref for each OTP input field
    useEffect(() => {
        scrollToTop();
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    // T&C & Privacy Modal
    const [showtermsModal, setshowtermsModal] = useState(false);
    const [showprivacyModal, setprivacyModal] = useState(false);

    const handletermsModalOpen = () => {
        setshowtermsModal(true);
    };

    const handleprivacyModalOpen = () => {
        setprivacyModal(true);
    };

    // OTP Modal
    const [showotpModal, setotpModal] = useState(false);

    const handleOtpInputChange = (index, value) => {
        // Check if the value is a single digit number
        if (/^\d$/.test(value) || value === '') {
            const updatedOtpInputs = [...otpInputs];
            updatedOtpInputs[index] = value;
            setOtpInputs(updatedOtpInputs);

            // Move focus to the previous input field if the value is deleted
            if (value === '' && index > 0) {
                inputRefs.current[index - 1].focus();
            } else {
                // Move focus to the next input field if available
                if (index < inputRefs.current.length - 1 && value.trim() !== '') {
                    inputRefs.current[index + 1].focus();
                }
            }

            // Check if all OTP inputs are filled
            const allInputsFilled = updatedOtpInputs.every(
                (input) => input.trim() !== ""
            );
            setIsFormValidOTP(allInputsFilled);
            const userInputOtp = updatedOtpInputs.join('');
            setUserInputOtp(userInputOtp);
        }
    };

    const handle2faSubmit = async (event) => {
        setIsLoading(true)
        setIsFormValid(false)
        event.preventDefault();
        const formData = {
            email: email,
            phone_number: phone,
            booking_slug: bookingSlug,
        };

        try {
            const result = await dispatch(checkInActions.emailVerification(formData));
            if (result?.payload?.status === HttpStatus.HTTP_OK) {
                setAuthCode(result?.payload?.result?.auth_code);
                setIsLoading(false);
                setIsFormValid(true);
                setotpModal(true);
            } else {
                toast(result?.payload?.message);
                setIsFormValid(true);
                setIsLoading(false);
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            setIsFormValid(true);
            setIsLoading(false);
            console.error('Error occurred while submitting form:', error);
        }
    };

    const handleResendOTP = async (event) => {
        event.preventDefault();
        // setIsFormValidOTP(false);
        // Implement your logic to resend OTP here
        const formData = {
            verification_code: "",
            is_resend: 1,
            auth_code: authCode,
            booking_slug: bookingSlug
        };
        try {
            const result = await dispatch(checkInActions.verifyOtp(formData));
            if (result?.payload?.status === HttpStatus.HTTP_OK) {
                setOtpInputs(["", "", "", "", "", ""]);
                // setIsFormValidOTP(true);
            }
        } catch (error) {
            // setIsFormValidOTP(true);
            console.error('Error occurred while submitting form:', error);
        }
    };

    const handleOtpPaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text");
        
        if (pastedData.length === otpInputs.length && /^\d+$/.test(pastedData)) {       // Only 6 digits opt to paste in form 
            const updatedOtpInputs = pastedData.split("");
            setOtpInputs(updatedOtpInputs);
            setIsFormValidOTP(true);
            setUserInputOtp(pastedData);
    
            inputRefs.current[otpInputs.length - 1].focus();    
        }
    };

    return (
        <section className="checkedinpage" >
            <h3 id="checkedInpage">Welcome! Let's get you checked in!</h3>
            <div className="card-section">
                <div className="cardYellow">
                    <figure>
                        {records?.listing?.image ? (
                            <img src={records?.listing?.image} alt='Listing Images' />
                        ) : (
                            <Skeleton duration={1} height={260} />
                        )}
                    </figure>
                    <div className="content">
                        <div className="checkedinDate">
                            <label>Check In</label>
                            <span className="dataLabel">{Moment(records?.checkin_datetime_format).format(global.dateTimeFormat)} </span>
                        </div>
                        <div className="checkedinDate">
                            <label>Check Out</label>
                            <span className="dataLabel"> {Moment(records?.checkout_datetime_format).format(global.dateTimeFormat)} </span>
                        </div>
                    </div>
                </div>

                <div className="cardOrange">
                    <div className="content">
                        <div className="checkedinDate">
                            <label>Primary Contact</label>
                            {/* <span className="dataLabel">{records?.first_name} {records?.last_name}</span> */}
                        </div>

                        <hr />

                        <Form onSubmit={handle2faSubmit}>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Mobile</Form.Label>
                                <PhoneInput
                                    country={"us"} // default country
                                    value={phone}
                                    className="input-text2"
                                    onChange={handlePhoneChange}
                                    inputProps={{
                                        name: "phone",
                                        required: true,
                                    }}
                                />
                                <Form.Text className="text-danger">{phoneError}</Form.Text>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput2"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    className="input-text2"
                                    placeholder="Please enter email..."
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <Form.Text className="text-danger">{emailError}</Form.Text>
                            </Form.Group>
                            <div className="d-flex justify-content-center">
                                <Button
                                    className="fillbutton"
                                    type="submit"
                                    disabled={!isFormValid}
                                >
                                    {isLoading ? (<><SpinnerLoader /></>) : ("Continue")}
                                </Button>
                            </div>
                        </Form>
                        <p>
                            By tapping Continue, I agree to the {" "}
                            <Link onClick={handletermsModalOpen} className="text-overline2">
                                Terms of Service
                            </Link>{" "}
                            and{" "}
                            <Link onClick={handleprivacyModalOpen} className="text-overline2">
                                Privacy Policy
                            </Link>
                            .
                        </p>
                    </div>
                </div>
            </div>

            {/* T&C */}
            <Modal
                show={showtermsModal}
                onHide={() => setshowtermsModal(false)}
                dialogClassName="modal-90w modal-bottom"
                aria-labelledby="example-custom-modal-styling-title"
                className="terms-privacy-policy"
            >
                <Modal.Header
                    closeButton
                    onClick={() => setprivacyModal(false)}
                ></Modal.Header>
                <Modal.Body>
                    <Form>
                        <h2 className="mb-2">Terms of Service</h2>
                        <div className="moreContent">
                            <Termsofserevice data={cmsContent?.term_of_service} />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* T&C */}

            {/* Privacy */}
            <Modal
                show={showprivacyModal}
                onHide={() => setprivacyModal(false)}
                dialogClassName="modal-90w modal-bottom"
                aria-labelledby="example-custom-modal-styling-title"
                className="terms-privacy-policy"
            >
                <Modal.Header
                    closeButton
                    onClick={() => setprivacyModal(false)}
                ></Modal.Header>
                <Modal.Body>
                    <Form>
                        <h2 className="mb-2">Privacy Policy</h2>
                        <div className="moreContent">
                            <Privacypolicy data={cmsContent?.privacy_policy} />
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Privacy */}

            {/* OTP Modalbox */}
            <Modal
                show={showotpModal}
                onHide={() => setotpModal(false)}
                dialogClassName="modal-90w modal-bottom"
                aria-labelledby="example-custom-modal-styling-title"
                className="otp-modal"
            >
                <Modal.Header
                    closeButton
                    onClick={() => setotpModal(false)}
                ></Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <h2 className="mb-2">Verification</h2>
                        <h6 className="mb-2 pb-2">We will send you a code to your email.</h6>

                        <Form.Group className="mb-4" controlId="otpnumber">
                            <div className="d-flex justify-content-center gap-2">
                                {otpInputs.map((input, index) => (
                                    <Form.Control
                                        key={index}
                                        type="tel" // Use type="text" to prevent number type validation
                                        className="input-text-otp"
                                        value={input}
                                        onChange={(e) => handleOtpInputChange(index, e.target.value)}
                                        maxLength={1} // Limit input length to 1 character
                                        ref={(ref) => inputRefs.current[index] = ref} // Ref for the input field
                                        required
                                        onPaste={index === 0 ? handleOtpPaste : null} 
                                    />
                                ))}
                            </div>
                            {/* <Form.Text className="text-danger">{otpError}</Form.Text> */}
                        </Form.Group>

                        <div className="d-flex justify-content-center">
                            <Button
                                className="fillbutton"
                                type="submit"
                                disabled={!isFormValidOTP}
                            >

                                {isLoading ? (<><SpinnerLoader /></>) : ("Verify")}
                            </Button>
                        </div>
                        <div className="d-flex justify-content-center mt-3">
                            <p>
                                <Link to="#" className="text-overline2" onClick={handleResendOTP}>
                                    Resend Code
                                </Link>
                            </p>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* OTP Modalbox */}
        </section>
    );
}

export default Checkedin;
