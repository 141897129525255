import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HouseCleaning from "../assets/images/house-cleaning.png";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "../store/common.slice";
import HttpStatus from "./../helpers/status.enum";
import SpinnerLoader from "../components/SpinnerLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Cleaning() {
  const { listingSlug } = useParams(); // Extract listingId from the URL

  const [buttonText, setButtonText] = useState("PROPERTY IS GUEST READY");
  const [isButtonDisabled, setButtonDesabled] = useState(true);
  const [record, setRecord] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    // Perform any actions that require the listingId
    // console.log("Listing ID:", listingSlug);
    const fetchData = async () => {
      try {
        const result = await dispatch(
          commonActions.todayScheduledCleaning(listingSlug)
        );
        if (result?.payload?.status === HttpStatus.HTTP_OK) {
          const responseResult = result?.payload?.result;
          setRecord(responseResult);
          if (!responseResult?.is_reservation) {  // if reservation is already cleaned or is ongoing then we are show this message
            setButtonText(responseResult.guest_ready_alert);
          } else {
            setButtonDesabled(false);
            setButtonText("PROPERTY IS GUEST READY");
          }
        }  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // Call the fetchData function inside useEffect
    fetchData();
  }, [listingSlug]);

  const markAsCleaned = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const formData = {
      listing_slug: listingSlug
    };
    const result = await dispatch(commonActions.markAsCleaned(formData));
    const data = result?.payload || null;
    if (data?.status === HttpStatus.HTTP_OK) {
      toast(data?.message);
      setButtonDesabled(true);
      setIsLoading(false);
      setButtonText("PROPERTY IS GUEST READY");
      // console.log("Marked as cleaned");
    } else {
      toast(data?.message);
      setButtonDesabled(true);
      setIsLoading(false);
      // console.error("Error to marked as cleaned");
    }
  };
  return (
    <section className="mainpage cleaning_section">
      <div className="content">
        <figure>
          <img src={HouseCleaning} alt="Happy Guest Logo" />
        </figure>
        <div className="w-100 p-2 text-center">
          <div className="cleaning_address">
            {/* <h4>140 and 142 Laprairie Street</h4> */}
            <h4
              dangerouslySetInnerHTML={{
                __html: record?.listing_address,
              }}
            />
          </div>
          <Button
            className="fillbutton checkout_day_btn"
            disabled={isButtonDisabled}
            onClick={markAsCleaned}
          >
            {isLoading ? (
              <>
                <SpinnerLoader />
              </>
            ) : (
              buttonText
            )}
          </Button>
        </div>
      </div>

      {/* request invitation */}
      {/* End request invitation */}
    </section>
  );
}

export default Cleaning;
