import { PaymentElement } from "@stripe/react-stripe-js";
import { Form, Button } from "react-bootstrap";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { onboardingActions } from "./../store/onboarding.slice";
import { paymentActions } from "./../store/payment.slice";
import HttpStatus from "./../helpers/status.enum";
import SpinnerLoader from "../components/SpinnerLoader";

const CheckoutForm = ({
  closeCheckoutModal,
  gotoHomePage,
  bookingSlug,
  clientSecret,
  customerId,
  stripeId,
  paymentSlug,
  intentType,
  cmsContent,
  securityDepositAlert,
}) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [loader, setLoader] = useState(false);

  useEffect(() => { }, []);

  const [errorCardMessage, setErrorCardMessage] = useState(null);
  const handlepageLoaderModalOpen = async (event) => {
    setLoader(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const payementResult = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
      // confirmParams: {
      //     return_url: "http://localhost:3000/reservationinfo",
      // },
    });

    if (payementResult.error) {
      setLoader(false);
      setErrorCardMessage(payementResult?.error?.message);

      console.log("Payement error message : ", payementResult?.error?.message);
      setTimeout(() => {
        setErrorCardMessage(null);
      }, 2000);
    } else {
      const formData = {
        booking_slug: bookingSlug,
        is_house_rules_accepted: 0,
        is_signed_on_dotted_line: 0,
        is_verification_ids_uploaded: 0,

        is_deposit_option: 1,
        deposit_option: intentType,
        card_token: stripeId,
      };

      setErrorCardMessage(null);
      try {
        const result = await dispatch(
          onboardingActions.submitOnboardingSteps(formData)
        );
        if (result?.payload?.status === HttpStatus.HTTP_OK) {
          const paymentFormData = {
            payment_slug: paymentSlug,
            booking_slug: bookingSlug,
            status_id: 1,
            final_payment_response: payementResult,
          };
          try {
            const resultPayment = await dispatch(
              paymentActions.updatePaymentStatus(paymentFormData)
            );
            if (resultPayment?.payload?.status === HttpStatus.HTTP_OK) {
              gotoHomePage(true);
            }
          } catch (error) {
            console.error(
              "Error occurred while updating payment status :",
              error
            );
          }
        }
      } catch (error) {
        console.error(
          "Error occurred while updating onbording status :",
          error
        );
      }
      setLoader(false);
    }
  };

  const setShowCreditCard = async () => {
    closeCheckoutModal(false);
  };

  return (
    <Form>
      <h2 className="mb-2">
        {intentType === 1 ? (
          <span
            className="checkout-card-title"
            dangerouslySetInnerHTML={{
              __html: cmsContent?.checkout_card_title_damage_waiver,
            }}
          />
        ) : (
          <span
            className="checkout-card-title"
            dangerouslySetInnerHTML={{
              __html: cmsContent?.checkout_card_title_security_hold,
            }}
          />
        )}
      </h2>
      <h6 dangerouslySetInnerHTML={{
        __html: intentType === 1 ? securityDepositAlert?.non_refundable_description : securityDepositAlert?.refundable_description,
      }}>
      </h6>
      <hr />
      <div className="paymentmethod pt-0 pb-2">
        <PaymentElement />
      </div>
      {errorCardMessage ? (
        <small className="text-danger mb-3 d-block">{errorCardMessage}</small>
      ) : (
        ""
      )}
      <div className="d-flex justify-content-between">
        <Button
          className="outlinebutton"
          onClick={() => setShowCreditCard(false)}
          disabled={loader ? true : false}
        >
          Cancel
        </Button>

        {/* disabled={buttonClicked} */}
        <Button
          className="fillbutton"
          onClick={handlepageLoaderModalOpen}
          disabled={loader ? true : false}
        >
          {loader ? (
            <>
              <SpinnerLoader />
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </div>
    </Form>
  );
};

export default CheckoutForm;
