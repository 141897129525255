export const commonRenderTextWithLinks = (text, fromUpsells = true) => {
  const linkRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g; // Updated regex to match URLs starting with www
  const parts = text?.split(linkRegex);

  const handleAnchorTag = (e) => {
    if(!fromUpsells) {
      e.stopPropagation();
    } 
  }

  return parts.map((part, index) => {
    if (linkRegex.test(part)) {
      // Regex to capture trailing punctuation (like ., ?, !, etc.)
      const lastCharRegex = /[-.,=!?;:"')]*$/;
      const lastCharMatch = part.match(lastCharRegex);
      // If we have trailing punctuation, isolate it
      const lastChar = lastCharMatch ? lastCharMatch[0] : "";
      // Remove the trailing punctuation from the URL
      const url = lastChar ? part.slice(0, -lastChar.length) : part;
      const href = url.startsWith('www') ? `http://${url}` : url; // Add http:// to URLs starting with www
      return (
        <>
          <a key={index} onClick={handleAnchorTag} href={href} target="_blank" rel="noopener noreferrer">
            {url}
          </a>
          {lastChar}
        </>
      );
    }
    return part;
  });
};
